import JsBarcode from 'jsbarcode';

const getBarCodeB64 = (code:string, displayValue = false, fontSize = 25) => {
    return new Promise((resolve,reject) => {
        const img = new Image();
        JsBarcode(img,code,{format: "CODE128",displayValue,fontSize,margin: 0});
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img,0,0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        }
        img.onerror = () => {
            reject();
        }
    })
}


const getLogoRcB64 = (tipo = 'osde') => {
    return new Promise((resolve,reject) => {
        const img = new Image();
        img.src = require(tipo === 'osde' ? "@/assets/images/logo/osde/RCOSDE01.png" : "@/assets/images/logo/viasano/vs.png")
        console.log(img.src)
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img,0,0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        }
        img.onerror = () => {
            reject();
        }
    })
}

const toIntlNumberFormat = (number:number, options:(Intl.NumberFormatOptions & { locale?:string }) | undefined = {
    locale:'es-AR',
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
}) => Intl.NumberFormat(options.locale,{...options}).format(number || 0)

export {
    getBarCodeB64,
    getLogoRcB64,
    toIntlNumberFormat,
}