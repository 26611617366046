<template>
    <div class='tw-relative not-found-page tw-w-full tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-8 tw-bg-gray-100 tw-space-y-2'>
        <div class='tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-flex tw-flex-col tw-justify-center tw-items-center '>
            <div class='tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-12 tw-py-16 tw-rounded-lg tw-shadow-lg tw-space-y-2'>
                <!-- <span class='tw-text-2xl'>Portal Inactivo</span> -->
                <span class='tw-text-5xl'>Portal Inactivo</span>
                <span class='tw-text-3xl'>El portal al que intenta acceder se encuentra inactivo.</span>
            </div>

        </div>
    </div>
</template>
<script lang='ts'>
import {defineComponent } from 'vue'

export default defineComponent({
    setup(props,{emit}){
        return{
        }
    }
})
</script>
<style lang='scss'>
.not-found-page{
    background-image:url('~@/assets/404-2.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    background-color: #eee;
    div{
        backdrop-filter: blur(50px);
        div{
            border: 1px solid #0066ffee;
            background:#004cbb;
        }
    }
    span{
        color:#eee;
        user-select: none;
    }
}
</style>