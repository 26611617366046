<template>
    <div class='tw-w-full!'>
        <div class='tw-w-full tw-flex tw-flex-col tw-items-start'>
            <span>Medicamentos solicitados</span>
            <TextArea style='box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2) inset,0px 0px 5px 15px rgba(255,255,255,0.5) inset' class='tw-w-full! tw-rounded tw-border tw-border-gray-300 tw-p-2' v-model="pedido" rows='5' cols='100' :autoResize="true"  />
        </div>
        <div class='tw-w-full tw-flex tw-justify-end tw-items-center tw-space-x-2 tw-px-2 tw-pt-2'>
            <Button label='Confirmar' @click='handleConfirmar' />
            <Button label='Cancelar' class='p-button-link' @click='$emit("close")' />
        </div>
    </div>
</template>


<script>
import { defineComponent , ref } from "vue";
import { useStore } from 'vuex';
import axios from '@/plugins/axios'

import { useToast } from "primevue/usetoast";

export default defineComponent({
    
    setup(props,{emit}) {
        const store = useStore();
        const toast = useToast();

        const pedido = ref('');


        const handleConfirmar = async () => {
            try {

                if(!pedido.value) {
                    toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Debe ingresar un pedido',
                        duration: 3000
                    });
                    return;
                }

                const {data:{cobertura}} = await axios.get(`/coberturas/get/${store.state.pacienteLogueado.idcobertura}`)
                const {data:{planesCobertura}} = await axios.get(`/planes-coberturas/get/${store.state.pacienteLogueado.idplancobertura}/${store.state.configuracionPortal.idcliente}`);
                
                
                const result = await axios.post(`/tareas-pendientes/${store.state.pacienteLogueado.id}`,{
                    datos:{
                        pedido: pedido.value,
                        nombrePaciente:store.state.pacienteLogueado.nombre,
                        hc: store.state.pacienteLogueado.numerodocumento,
                        cobertura: cobertura.nombre,
                        planCobertura: planesCobertura.nombre,
                    }
                })
                toast.add({
                    severity: 'success',
                    summary: 'Solicitud de receta',
                    detail: 'Solicitud de receta enviada con éxito',
                    life: 3000
                });
                
                emit('close',result.data);
            } catch (error) {
                console.log(error);
                toast.add({
                    severity: 'error',
                    summary: 'Solicitud de receta',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
        }



        return {
            pedido,
            handleConfirmar,
        }
    }
})
</script>


<style lang="css">
    
</style>