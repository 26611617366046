<template>
    <div class='cambio-pass tw-w-full tw-h-screen tw-flex tw-justify-center tw-items-center tw-p-2' :style='`background-image:url(${BackgroundImage});`'>

        <Card class=' tw-w-full  md:tw-w-6/12 lg:tw-w-5/12 xl:tw-w-3/12  tw-p-4 p-shadow-5' :class='{"animate-in":!isOut,"animate-out":isOut}'>
            <template #title >
                <h2 >Bienvenido!, {{$store.state.pacienteLogueado?.nombre || '' }}</h2>
            </template>
            <template #subtitle >
                <h2 >para continuar debera ingresar una nueva contraseña.</h2>

                <hr>
            </template>
            <template #content>
                            <div  class='p-fluid tw-space-y-4'>
                                <div class='p-field'>
                                    <Password placeholder='Nueva Contraseña' toggleMask v-model="password" >
                                        <template #footer>
                                            <Divider />
                                            <p class="p-mt-2">Sugerencias:</p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                                <li>Minimo 8 caracteres</li>
                                                <li>Una letra en mayusculas</li>
                                                <li>al menos un numero</li>
                                            </ul>
                                        </template>
                                    
                                    </Password>
                                </div>
                                <div class='p-field'>
                                    <Password :loading='isLoad' placeholder='Repita la Contraseña' toggleMask v-model="passwordRepeat" :feedback="false" />
                                </div>
                                
                                <span class='tw-w-full tw-text-sm tw-text-red-500'>{{error}}</span>
                            </div>
            </template>
            <template #footer >
                <div class='tw-space-y-2'>
                    <Button label="Ingresar" class='tw-w-full ' @click="handleClick"/>
                    <hr>
                    
                </div>
                <div class='tw-mt-2'>
                    <span class='tw-text-xs'>Desarrollado por Gobal App®</span>
                </div>
            </template>
        
        </Card>
        
    </div>
</template>

<script lang='ts'>
import {defineComponent, ref} from 'vue'
import useBackground from '@/hooks/useBackground'
import axios from '@/plugins/axios'
import { useRoute, useRouter } from 'vue-router'


export default defineComponent({
    setup(){
        const BackgroundImage = useBackground()
        const password = ref('');
        const router = useRouter();
        const route = useRoute();
        const isLoad = ref(false);
        const isOut = ref(false);
        const passwordRepeat = ref('');
        const error = ref();

        const validarContraseñas = () => {
            let valida = true;

            valida = valida && password.value === passwordRepeat.value;

            if(!valida){
                error.value = 'las contraseñas deben ser iguales';
                passwordRepeat.value = '';
                return valida;
            }

            valida = valida && !(password.value.length < 8);

            if(!valida) {
                error.value = 'la contraseña debe tener mas de 8 caracteres';
                password.value = '';
                passwordRepeat.value = '';
                return valida
            }

            return valida;
        }


        const handleClick = () =>{

            if(validarContraseñas()){
                isLoad.value = true;
                axios.post('pacientes/cambio-password', {password:password.value})
                .then(()=>{
                    isOut.value = true;
                    setTimeout(() => {
                        const { cliente } = route.params
                        router.push(`/${cliente}/`);
                    },500)
                })
                .catch(console.log)
                .finally(()=>isLoad.value =false)
            }

        }


        return {
            password,
            passwordRepeat,
            error,
            isLoad,
            isOut,
            handleClick,
            BackgroundImage
            }
    }
})
</script>


<style lang="css">
    .cambio-pass .animate-in{
        opacity:0;
        animation: entrada .5s forwards;
    }
    .cambio-pass .animate-out{
        opacity:0;
        animation: salida .5s forwards;
    }

    @keyframes entrada {
        from{
            opacity:0;
            transform:translateY(-100vh);
        }to{
            opacity:1;
            transform:translateY(0);
        }
    }
    @keyframes salida {
        from{
            opacity:1;
            transform:translateY(0);
        }to{
            opacity:0;
            transform:translateY(-100vh);
        }
    }

</style>