<template>
  <div class='historial-turnos animate tw-h-full lg:tw-pt-0 tw-w-full tw-flex tw-flex-col  tw-p-2 lg:tw-flex-row tw-justify-center tw-items-center'>
            <Toast position="bottom-right"/>  
            <div class='tw-w-full tw-h-full '>
                <Card style="background:none;box-shadow:none;" class='animate tw-h-full tw-w-full'>
                        <template #title>
                            <div class='tw-w-full tw-flex tw-justify-between tw-items-center tw-rounded tw-bg-gray-50 tw-p-4'>
                                <span>Próximas Citas</span>
                                <Button v-if='isHistoricoActive' label="Historial"   @click="handleHistorialClick(true)"></Button>
                            </div>
                        </template>
                        <template #content> 
                            <div style='max-height:calc(100vh - 15em)' class=' tw-bg-gray-50 tw-overflow-auto tw-rounded tw-p-2'>
                                
                                <div v-if='datosTurnos && datosTurnos.length > 0' class='tw-p-2 tw-space-y-2'>
                                    <proximos-turnos  @turnoEliminado='handleTurnoEliminado' v-for="turno in datosTurnos" :key="turno" :turno='turno' />
                                </div>
                                <div v-else class='tw-w-full tw-bg-gray-50'>
                                    <span> No posee citas disponibles.</span>
                                </div>
                            </div>
                        </template>
                </Card>
            </div>

            <!-- SideBar -->
            <Sidebar v-model:visible="slideVisible" position='right' class="tw-w-full! md:tw-w-2/3! xl:tw-w-1/2! tw-text-center!">
                <h3 class='tw-text-xl'><strong>Historial de Citas</strong></h3>

                <DataTable class='table-historial-turnos' style='' :value="datosHistorico" responsiveLayout="stack" scrollHeight="80vh" >
                    <template #empty>
                        No se encontraron datos.
                    </template>
                    <Column field="fecha"  class='tw-w-24'  header="Fecha"></Column>
                    <Column field="hora" class='tw-w-12' header="Hora"></Column>
                    <Column field="especialidad"  header="Especialidad"></Column>
                    <Column field="profesional" header="Profesional"></Column>
                    <Column field="vino" class='tw-w-12' header="Presente"></Column>
                </DataTable>
            </Sidebar>
  </div>
</template>

<script lang="ts">


//Utils
import moment from 'moment'
import { useToast } from "primevue/usetoast";

//Table
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

//Side
import Sidebar from 'primevue/sidebar';


//Componentes
import ProximosTurnos from '@/components/ProximosTurnos.vue'


import axios from '@/plugins/axios';


import { computed, defineComponent ,onMounted,ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'


const encodeURIComponente = (objeto:any) => {
    return encodeURIComponent(JSON.stringify(objeto))
}

export default defineComponent({
    components:{
        DataTable,
        Column,
        Sidebar,
        ProximosTurnos,
    },
    setup(){
        
        const store = useStore();
        const toast = useToast();
        const route = useRoute();

        const datosHistorico = ref()
        const datosTurnos = ref()
        const slideVisible = ref(false);

        const handleHistorialClick = (bool:boolean) => {
            slideVisible.value = bool
        }


        const obtenerTurnosPacienteHistorico = async () => {
            const filters = {
                    idcliente:store.state.configuracionPortal.idcliente,
                    idpaciente:store.state.pacienteLogueado.id,
                    fecha:{ 'lte':moment()},  
            }

            


            try {
             
                const { data } = await axios.get(`turnos?filters=${encodeURIComponente(filters)}`)
                

                const hora = moment().format('HH:mm:ss')
                const fecha = moment().format('YYYY-MM-DD');

                console.log(data.turnos)

                datosHistorico.value = data.turnos
                .filter((turno:any) => {
                    if(turno.fecha === fecha){
                        
                        return turno.hora <= hora;
                    }
                    return true;
                })
                .map((turno:any) => ({
                    id:turno.id, 
                    fecha:moment(turno.fecha,'YYYY-MM-DD').format('DD/MM/YYYY'),
                    hora:moment(turno.hora,'HH:mm:ss').format('HH:mm'),
                    especialidad:turno.medicoEspecialidad?.servicioEspecialidad?.descripcion || turno.servicioEspecialidad?.descripcion || '-',
                    profesional:turno.medicoEspecialidad?.medico?.nombre || '-',
                    sucursal:`${turno.medicoEspecialidad?.sucursal?.direccion} - ${turno.medicoEspecialidad?.sucursal?.direccion}`,
                    vino:turno.presente ? 'Si' : 'No'
                }))

                



            } catch (error) {
                console.log(error);
            }
        }


        const obtenerTurnosPaciente = async () => {
                try {

                    const filters = {
                        idcliente:store.state.configuracionPortal.idcliente,
                        idpaciente:store.state.pacienteLogueado.id,
                        fecha:{ '>=':moment()},
                        idmedicosespecialidad:{ne:null}
                    }

                    const fecha = moment().format('YYYY-MM-DD');
                    const hora = moment().subtract(30, 'minutes').format('HH:mm:ss');

                    const includes = [
                        {model:'turnoVideoconsultas'},
                        {model:'turnoPrestacion',include:[{model:'prestacion',include:[{model:'instruccionPrestacion'}]}]}
                    ]
                    const { data } = await axios.get(`turnos?filters=${encodeURIComponente(filters)}&includes=${encodeURIComponente(includes)}`)
                    

                    datosTurnos.value = data.turnos.filter((turno:any) => {
                        if(turno.fecha === fecha){
                            return turno.hora >= hora;
                        }
                        return true;
                    })
                    .sort((a:any,b:any) => {
                        if(a.fecha === b.fecha){
                            return a.hora > b.hora ? 1 : -1
                        }
                        return a.fecha > b.fecha ? 1 : -1
                    })

                    const { q } = route.query;

                    if(q){
                        
                        const index = datosTurnos.value.findIndex((turno:any)=> `${turno.id}` === q );
                        if(index !== -1){
                            const turno = datosTurnos.value[index];
                            datosTurnos.value.splice(index,1);
                            datosTurnos.value.splice(0,0,turno);
                        }



                    }

                    
                    
                } catch (error) {
                    console.log(error);
                }
        }

        const handleTurnoEliminado = () => {
            toast.add({severity:'success',summary:'Exito',detail:'Cita eliminada.',life:1500})
            obtenerTurnosPaciente();
        }


        const isHistoricoActive = computed(() => store.getters.isHistoricoCitasActive)



        onMounted(() => {
            
            if(isHistoricoActive.value) obtenerTurnosPacienteHistorico()
            obtenerTurnosPaciente()
        })
        
        return { 
            isHistoricoActive,
            datosHistorico,
            datosTurnos,
            slideVisible,
            handleHistorialClick,
            handleTurnoEliminado }
    }
});
</script>
<style lang='scss'>

.table-historial-turnos {
    width:98% !important;
    & .p-datatable-wrapper .p-datatable-table .p-datatable-tbody {
        @media screen and (max-width: 960px){
            & > tr > td {
                padding:0 !important;
            }
        }
    }

}

.historial-turnos.animate{
    animation: entrada-historial .2s ease forwards;
}

@keyframes entrada-historial {
    0%{
        opacity: 0;
        
    }100%{
        opacity: 1;
        
    }
}




</style>