<template>
    <div class='tw-flex-1 tw-w-full tw-px-2 tw-pb-2 tw-flex tw-flex-col tw-gap-2'>
        <div class="p-component p-card p-card-title tw-w-full tw-bg-gray-50 tw-rounded tw-p-2">
            <span style="
                font-size: 1.5rem;
                font-weight: 700;
            ">Guardia Virtual</span>
        </div>
        <template v-if="turnoRecepcionado">
            <div class="p-component p-card tw-bg-gray-50 card-demanda-virtual tw-flex-1 tw-overflow-auto tw-flex tw-w-full tw-bg-transparent tw-rounded tw-border tw-shadow">
                <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
                    <div class="tw-w-full tw-flex tw-flex-1 tw-flex-1 p-3">
                        <div v-if='urlLLamadaVirtual' class="tw-flex-1 tw-bg-primary tw-rounded tw-overflow-hidden">
                            <iframe
                                allowfullscreen
                                sandbox="allow-scripts allow-presentation allow-same-origin allow-downloads"
                                allow="autoplay; camera; microphone; web-share; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write; downloads;"
                                type="text/html"
                                class="tw-w-full tw-h-full"
                                :src="urlLLamadaVirtual"
                                frameborder="0"
                            ></iframe>
                        </div>

                        <div v-else class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-relative">
                            <a @click="cancelarCita" class=" tw-top-0 tw-right-0 tw-absolute tw-cursor-pointer tw-underline tw-text-primary">Cancelar</a>
                            <div class="icon-container tw-p-8">
                                <svg
                                    width="4rem"
                                    height="4rem"
                                    viewBox="0 0 48 48" 
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="Waiting_Area" data-name="Waiting Area">
                                        <path d="m47 30h-4a1 1 0 0 0 0 2h1v7h-5v-1a3 3 0 0 0 0-6h-2v-1a5 5 0 0 0 4.73-5.28l-.61-9.88a3 3 0 0 0 -3-2.81h-10.24a3 3 0 0 0 -3 2.81l-.61 9.88a5 5 0 0 0 4.73 5.28v1h-2a3 3 0 0 0 0 6v1h-6v-1a3 3 0 0 0 0-6h-2v-1a5 5 0 0 0 4.73-5.28l-.61-9.88a3 3 0 0 0 -3-2.81h-10.24a3 3 0 0 0 -3 2.81l-.61 9.88a5 5 0 0 0 4.73 5.28v1h-2a3 3 0 0 0 0 6v1h-5v-7h1a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2h1v8a1 1 0 0 0 1 1h4v5h-4a1 1 0 0 0 0 2h43a1 1 0 0 0 0-2h-5v-5h4a1 1 0 0 0 1-1v-8h1a1 1 0 0 0 0-2zm-20.74-4.19.62-9.87a1 1 0 0 1 1-.94h10.24a1 1 0 0 1 1 .94c.88 14.65 2.28 13.06-9.86 13.06a3 3 0 0 1 -3-3.19zm4.74 5.19h4v1h-4zm-4 3h12a1 1 0 0 1 0 2h-12a1 1 0 0 1 0-2zm2 4h8v1h-8zm-20.74-12.19.62-9.87a1 1 0 0 1 1-.94h10.24a1 1 0 0 1 1 .94c.64 10.28 1 10.84-.19 12.11s-2 .95-9.67.95a3 3 0 0 1 -3-3.19zm4.74 5.19h4v1h-4zm-4 3h12a1 1 0 0 1 0 2h-12a1 1 0 0 1 0-2zm2 4h8v1h-8zm28 8h-30v-5h30z"/>
                                        <path d="m42 12a6 6 0 1 0 -6-6 6 6 0 0 0 6 6zm0-10a4 4 0 1 1 -4 4 4 4 0 0 1 4-4z"/>
                                        <path d="m42.79 8.21a1 1 0 0 0 1.42-1.42l-1.21-1.2v-1.59a1 1 0 0 0 -2 0c0 2.94-.33 2.08 1.79 4.21z"/>
                                    </g>
                                </svg>
                            </div>
                            <div v-if="counterAndTime" class="">
                                <span class="tw-text-3xl tw-font-bold">{{ counterAndTime?.count}}</span> <br>personas en espera
                                <div v-if="counterAndTime.count" class="tw-text-sm">Sera {{ sexoPaciente[0] === 'F' ? 'atendida' : 'atendido' }} en aprox. <span class="tw-font-semibold">{{ counterAndTime?.time }}</span>.</div>
                                <div v-else class="">En unos instantes será {{ sexoPaciente[0] === 'F' ? 'atendida' : 'atendido' }}.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <Card class="tw-my-auto animate" style="max-width:25rem;">
                <template #title>
                    <span v-if="!turnoAPagar">Nueva Atención</span>
                    <span v-else>Abonar Atención</span>
                </template>
                <template v-if="turnoAPagar" #subtitle>
                    <span>
                        Para continuar con la recepción
                        deberá abonar el siguiente importe <span class="tw-font-bold">{{ toIntlNumberFormat(turnoAPagar?.metadata?.paymentInfo?.amount || 0) }}</span>
                    </span>
                </template>
                <template #content>
                    <div  v-show="!turnoAPagar" class="tw-flex tw-flex-col tw-gap-3 tw-h-full" style="aspect-ratio:1/.6;min-height:10rem;">
                        <Dropdown @change='()=>{}' class='tw-w-full' showClear v-model="servicioSeleccionado" :options="serviciosVirtuales" optionLabel="descripcion" placeholder="Servicio" filterPlaceholder="Buscar.."/>
                        <Button label='Entrar en Guardia' class='tw-w-full tw-mt-auto' @click="handleSolicitarCita"></Button>
                    </div>
                    <div v-show="turnoAPagar" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
                        <Button label="Abonar con Mercado Pago" icon="pi pi-external-link" iconPos="right" @click="pagarTurno">
                        </Button>
                        <a @click="cancelarCita" class="tw-cursor-pointer tw-underline tw-text-primary">Cancelar</a>
                    </div>
                </template>
            </Card>
        </template>
    </div>
</template>
<script lang='ts'>
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { toIntlNumberFormat } from '@/utils'
import axios from '@/plugins/axios'
import moment from 'moment'


enum PAYMENT_STATUS {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    ERROR = 'error',
}

export default defineComponent({
    setup(){
        const store = useStore()
        const toast = useToast()
        const dialog = useConfirm()

        const serviciosVirtuales = ref<any[]>([])
        const servicioSeleccionado = ref<any>(null)
        const turnoRecepcionado = ref<any>(null)
        const turnoAPagar = ref<any>(null)
        const timer = ref<any>(null)
        const counterAndTime = ref<any>(null)
        
        const getServiciosVirtuales = async () => {
            try {
                const filters = {
                    tipo: 'S',
                    demanda_virtual:true,
                }

                const includes = [{
                    model:'prestacion',
                    required:true,
                }]

                const { data } = await axios.post('especialidades-servicios/get-all',{
                    filters:JSON.stringify(filters),
                    includes:JSON.stringify(includes)
                })

                serviciosVirtuales.value = data.servEsp

            } catch (error) {
                console.log(error);
            }
        }

        const getTurnoRecepcionado = async () => {
            const idpaciente = store.state.pacienteLogueado?.id;
            const fechaInicio = moment().set({hour:0,minute:0,second:0,millisecond:0})
            const fechaFin = moment().set({hour:23,minute:59,second:59,millisecond:0})
            try {
                const includes = encodeURIComponent(
                    JSON.stringify([{
                        model:'turno',
                        required:true,
                        include:[
                        {
                            model:'servicioEspecialidad',
                            attributes:['demanda_virtual'],
                            where:{
                                demanda_virtual:true
                            },
                            required:true,
                        },
                        {
                            model:'turnoVideoconsultas',
                            required:true,
                        },
                        {
                            model:'paciente',
                            required:true,
                            where:{
                                id:idpaciente
                            },
                        },
                        {
                            model:'cobertura'
                        },
                        {
                            model:'planCobertura'
                        },
                        {
                            model: 'tipoTurno'
                        }]
                    }])
                );
                const filters = encodeURIComponent(JSON.stringify({
                    estado:'En espera',
                    recepcion:{'between':[fechaInicio.toISOString(), fechaFin.toISOString()]},
                }));

                const order = encodeURIComponent(JSON.stringify([['turno', 'hora', 'ASC'], ['turno', 'fecha', 'ASC']]));

                const { data } = await axios.get(`/turnos-presentes?includes=${includes}&filters=${filters}&page=1&pageSize=1&order=${order}`);
                turnoRecepcionado.value = data.rows[0] || null

                if(turnoRecepcionado.value && !turnoRecepcionado.value.idmedicosespecialidad){
                    if(!counterAndTime.value){
                        getCounterAndTime()
                    }
                    timer.value = setTimeout(() => {
                        getTurnoRecepcionado()
                        getCounterAndTime()
                    }, 2000)
                    return;
                }


            } catch (error) {
                console.log(error);
            }
        }

        const getCounterAndTime = async () => {
            try {
                if(!turnoRecepcionado.value){
                    return;
                }

                const { data } = await axios.get(`/turno/get-counter-and-time`,{
                    params:{
                        idrecepcion:turnoRecepcionado.value.id,
                        idespecialidad:turnoRecepcionado.value.turno.idservicioespecialidad,
                    }
                })
                const { count, tiempoPromedio:{hours, minutes, seconds, milliseconds}} = data;
                const multiplier = count || 1;
                const tiempo = moment().set({
                    hours:hours * multiplier, 
                    minutes:minutes * multiplier,
                    seconds:seconds * multiplier,
                    milliseconds:milliseconds * multiplier
                }).diff(moment().set({hours:0, minutes:0, seconds:0, milliseconds:0}),'milliseconds');
                const time = moment.duration(tiempo,'milliseconds').locale('es').humanize()

                counterAndTime.value = {
                    count,
                    time,
                }
            } catch (error:any) {
                toast.add({
                    life: 3000,
                    severity:'error',
                    summary:'Atencion',
                    detail:error.response?.data?.message || error.message || 'Error al obtener el tiempo de espera',
                });
            }
        }

        const getTurnoAPagar = async () => {
            try {
                const { id } = store.state.pacienteLogueado;
                const fechaInicio = moment().set({hour:0,minute:0,second:0,millisecond:0})
                const fechaFin = moment().set({hour:23,minute:59,second:59,millisecond:0})
                const filters = {
                    idpaciente: id,
                    fecha: {
                        between: [fechaInicio.toISOString(), fechaFin.toISOString()],
                    },
                    presente:false,
                }

                const includes = [{
                    model:'servicioEspecialidad',
                    required:true,
                    where:{
                        demanda_virtual:true
                    },
                },{
                    model:'turnoPrestacion',
                    required:true,   
                },
                {
                    model:'turnoVideoconsultas',
                    required:true,
                },
                {
                    model:'turnoRecepcionado',
                    required:false,
                }]

                const { data } = await axios.get('turnos/get-all',{
                    params:{
                        filters:JSON.stringify(filters),
                        includes:JSON.stringify(includes),
                        withPaymentInfo:true,
                    }
                })

                const dataFiltered = data.rows.filter((turno:any) => turno.turnoRecepcionados?.[0]?.estado !== 'Llamado')

                
                turnoAPagar.value = dataFiltered[0] || null

                if(!turnoAPagar.value){
                    return;
                }

                if(turnoAPagar.value?.metadata?.paymentInfo?.status === PAYMENT_STATUS.APPROVED || !turnoAPagar.value?.metadata?.mercadoPagoData){
                    recepcionarTurno()
                    return;
                }

                if(turnoAPagar.value){
                    setTimer()
                }

            } catch (error) {
                console.log(error)
            }
        }

        const handleSolicitarCita = () => {
            if(!serviciosVirtuales.value.length){
                toast.add({severity:'error', summary:'Atencion', detail:'No hay servicios disponibles'})
                return;
            }
            if(!servicioSeleccionado.value){
                toast.add({severity:'error', summary:'Atencion', detail:'Seleccione un servicio'})
                return;
            }
            const { id } = store.state.pacienteLogueado;
            const { cliente } = store.state.configuracionPortal
            const turno = {
                idpaciente: id,
                idservicioespecialidad: servicioSeleccionado.value?.id,
                idusuario: cliente.usuarios[0]?.id,
            }
            solicitarCita(turno)
        }

        const solicitarCita = async (turno:any) => {
            try {
                const turnoResponse = await axios.post('/turnos/create-demanda-virtual',turno)
                if(!turnoResponse.data.metadata?.paymentInfo){
                    recepcionarTurno(turnoResponse.data)
                    return;
                }
                turnoAPagar.value = turnoResponse.data
            } catch (error:any) {
                toast.add({
                    severity:'error', 
                    summary:'Atencion', 
                    detail:error.response?.data?.message || error.message || 'Error al solicitar la cita'}
                )
                console.log(error);
            }
        }

        const pagarTurno = () => {
            const a = document.createElement('a')
            a.href = turnoAPagar.value.metadata?.mercadoPagoData?.link
            a.target = '_blank'
            a.click()
            setTimer()
        }

        const cancelarCita = async () => {
            const response = await openConfirmDialog('¿Está seguro que desea cancelar la cita?')
            if(response){
                try {
                    await axios.delete(`/turnos/${turnoAPagar.value?.id || turnoRecepcionado.value?.idturno}`)
                    getTurnoAPagar()
                    turnoRecepcionado.value = null
                } catch (error) {
                    console.log(error);
                }
            }
        }

        const openConfirmDialog = (text:string) => {
            return new Promise((resolve,reject) => {
                dialog.require({
                    message: text,
                    header: 'Confirmar',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => resolve(true),
                    reject: () => resolve(false)
                });
            })
        }

        const setTimer = () => {
            setTimeout(() => {
                clearInterval(timer.value)
                timer.value = setInterval(() => {
                    getPaymenData()
                }, 2000)
            }, 3000);
        }

        const getPaymenData = async () => {
            try {
                const { data } = await axios.get(`/turnos/get-payment-data/${turnoAPagar.value.id}`)
                if(data.status === PAYMENT_STATUS.APPROVED){
                    recepcionarTurno()
                    clearInterval(timer.value)
                }
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        }

        const recepcionarTurno = async (turnoARecepcionar?:any) => {
            try {
                const { id , metadata, ...turno} = turnoARecepcionar || turnoAPagar.value
                await axios.get(`/turnos/recepcionar-demanda-virtual/${id}`,{
                    id,
                    ...turno,
                    presente:true,
                })
                clearInterval(timer.value)
                await getTurnoRecepcionado()
                turnoAPagar.value = null
            } catch (error) {
                console.log(error);
            }
        }

        const urlLLamadaVirtual = computed(() => {
            const urlbase = store.getters.getUrlBase
            if(!turnoRecepcionado.value?.turno?.turnoVideoConsulta || !turnoRecepcionado.value?.turno?.idmedicosespecialidad){
                return ''
            }
            
            return `${urlbase}/room/${turnoRecepcionado.value.turno.turnoVideoConsulta.idsala}`
        })

        const sexoPaciente = computed(() => store.state.pacienteLogueado.sexo)

        onMounted(()=>{
            getServiciosVirtuales()
            getTurnoRecepcionado()
            getTurnoAPagar()
        })

        onUnmounted(()=>{
            clearInterval(timer.value)
        })

        return{
            counterAndTime,
            turnoAPagar,
            serviciosVirtuales,
            turnoRecepcionado,
            servicioSeleccionado,
            urlLLamadaVirtual,
            sexoPaciente,
            handleSolicitarCita,
            recepcionarTurno,
            pagarTurno,
            cancelarCita,
            toIntlNumberFormat,
        }
    }
})
</script>
<style lang='scss' scoped>
.icon-container{
    --animation-speed: 3s;
    border-radius: 999px;
    border:2px solid lch(from var(--primary-color) l c h);
    border-bottom-color: transparent;
    border-top-color: transparent;
    animation: spin var(--animation-speed) linear infinite;
}
.icon-container svg {
    animation: spin var(--animation-speed) linear infinite reverse;
    g{
        fill: lch(from var(--primary-color) l c h);  
        mix-blend-mode: multiply;
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.card-demanda-virtual{
    scrollbar-gutter: stable;
}

.animate{
    animation: entrada-nuevo-turno .2s ease forwards;
}

</style>