
const BASE_URL = process.env.VUE_APP_BUILD_MODE === 'staging' ? 'https://testing-portal.api.globalapp.ar/api/' : process.env.NODE_ENV == 'production' ? process.env.VUE_APP_BASE_URL : `${location.protocol  }//${  location.hostname  }:4000/api/`
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true
const config = {
   baseURL: BASE_URL,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const token = localStorage.getItem('token')
    if(token) {
      config.headers['Authorization'] = token
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    if(response.headers['authorization']) {
      localStorage.setItem('token', response.headers['authorization'])
    }
    return response;
  },
  function(error) {
    if(error.response.status === 401) {
      const path =  window.location.pathname
      const pathSplitted = path.split('/')
      if(pathSplitted?.[2] !== 'login') {
        window.location.href = `/${pathSplitted[1]}/login`
      }
    }
    return Promise.reject(error);
  }
);

export { BASE_URL }
export default _axios;
