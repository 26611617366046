<template>
    <div class='tw-w-full tw-flex tw-justify-center tw-items-center tw-p-2 recetas'>
        <Toast position="bottom-right"/>
        <Card style='background:none; box-shadow:none;' class='animate tw-m-auto tw-w-full'>
            <template #title>
                <div class='tw-w-full tw-bg-gray-50 tw-rounded tw-shadow tw-py-4 tw-px-8 tw-text-center'>
                    <h3>Ordenes</h3>
                </div>
            </template>
            <template #content>
            
                <div v-if='ordenesList.length > 0' class="tw-flex " style="max-height:calc(100vh - 28vh);" >
                    <div ref='contenedorResultados' class='tw-w-full tw-overflow-auto tw-flex-1 tw-grid xl:tw-grid-cols-2 tw-gap-2 tw-bg-gray-100 tw-p-2 tw-rounded'>
                        <recetas-card v-for="item in ordenesList" :key="item" :dato='item' @descargar='descargarRecetas(item, $event)' :isLoading='item.isLoading' :showEstado='true'/>
                    </div>
                </div>

                <div v-else class='tw-w-full tw-bg-gray-50 tw-rounded tw-shadow tw-py-4'>
                    <span>No se encontraron datos.</span>
                </div>
            </template>
        </Card>
    </div>
</template>

<script lang='ts'>
import { defineComponent,ref, onBeforeMount, computed, watchEffect } from "vue";
import { useStore } from 'vuex';
import axios from '@/plugins/axios';
import { jsPDF } from 'jspdf';
import { imprimirRC }from '@/hooks/useImpresion'
import useLogo from '@/hooks/useLogo';


import RecetasCard from '@/components/RecetasCard.vue'


import { useToast } from "primevue/usetoast"

export default defineComponent({
    components:{
        RecetasCard,
    },
    setup(){
        const store = useStore();
        const logo = useLogo();
        const toast = useToast();

        const solicitudRecetaForm = ref(false);
        const ordenesList = ref([]);
        
        const contenedorResultados = ref<HTMLDivElement>();
        const itemsPorPagina = ref(10);
        const paginaActual = ref(1);
        const cantidadResultados = ref(0);

        
        const totalPaginas = computed(() => {
            return Math.ceil(cantidadResultados.value / itemsPorPagina.value);
        });


        const descargarRecetas = async (receta:any, auditoria:any)=>{
            try {
                receta.isLoading = true;
                const url = receta.tipo === 'RC' ? `/receta/get-pdf?id=${receta._id}` : `/auditoria-prestacional/get-print/${auditoria.uuid}`;
                const {data} = await axios.get(url, {responseType:'blob'});
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                    const a = document.createElement('a');
                    a.href = reader.result as string;
                    a.download = receta.tipo === 'RC' ? `Receta_${receta._id}.pdf` : `Auditoria_${auditoria.uuid}.pdf`;
                    a.click();
                }
                await new Promise((resolve) => setTimeout(resolve, 2000));
            } catch (error) {
                console.log(error);
            } finally { 
                receta.isLoading = false;
            }

        }

        const getCondicionesPacientes = async ({numerodocumento}:any) => {
            try {
                let condiciones : Record<string,any>  = {}
                const { data:afiliado } = await axios.get('/cobertura-padron/get-all', {
                    params:{
                        nroAfiliado:numerodocumento,
                        verificarPadron: true
                    }
                })

                condiciones = {
                    'Cronico':afiliado[0]?.cronicidad === 'Si',
                    'Discapacidad':afiliado[0]?.incapacidad === 'Si',
                    'PMI':afiliado[0]?.pmi === 'Si',
                }

                Object.keys(condiciones).forEach((key) => {
                    if(!condiciones[key]) delete condiciones[key]
                })

                return Object.keys(condiciones).join(', ') || '-';
                
            } catch (error) {
                console.log(error);
                return '';   
            }
        }


        const obtenerOrdenesPaciente = async () =>{
            try {
                const query = encodeURIComponent(JSON.stringify({
                    $or:[
                        { $and:[{tipo:'RC'},{ "datos.items": { $elemMatch: { practica: { $exists: 1 } }}}] },
                        { $and:[{tipo:'OR'},{'datos.tipoSolicitud':'prestacion'}] }
                    ]
                }));
                
                const {data} = await axios.get(`/historia-clinica-get-registros/${store.state.pacienteLogueado.id}/${paginaActual.value}/${itemsPorPagina.value}?query=${query}`);
                
                ordenesList.value = paginaActual.value === 1 ? data.registros : [...ordenesList.value, ...data.registros];
                
                if(paginaActual.value === 1) cantidadResultados.value = data.count;
                
            } catch (error) {
                console.log(error);
            }
        }


        onBeforeMount(() => {
            obtenerOrdenesPaciente();
        })

        watchEffect(() => {
            
            if(contenedorResultados.value){
                contenedorResultados.value.removeEventListener('scroll', eventoDeScroll);
                contenedorResultados.value.addEventListener('scroll', eventoDeScroll);
            }
        })

        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
            
            if(scrollTop + offsetHeight >= scrollHeight){
               
                paginaActual.value += 1;
                if(totalPaginas.value >= paginaActual.value){
                    obtenerOrdenesPaciente();
                }
                
                
            }
        }




        return {
            contenedorResultados,
            ordenesList,
            descargarRecetas,
        }

    }

})
</script>

<style>

.recetas .animate{
    animation: entrada-recetas .2s ease forwards;
}
@keyframes entrada-recetas {
    0%{
        opacity: 0;
       
    }100%{
        opacity: 1;
        
    }
}

</style>