<template>
    <div class='tw-w-full'>
        <Card class='p-shadow-5 tw-h-full'>
            <template #content>
            
            
                <div class='tw-w-full tw-h-full tw-flex tw-flex-col md:tw-flex-row tw-items-center'>
                    <div class='tw-flex tw-flex-col tw-gap-1 tw-text-left tw-px-2'>
                        <div class='tw-select-none tw-text-xs'>
                            <span 
                                v-if='!!estado'
                                class="tw-px-2 tw-py-1 tw-rounded tw-shadow" 
                                :style='`
                                    --estado-color:${estado.color || "#fafafa"};
                                    outline: 1px solid lch(from var(--estado-color) l calc(c + 10) h); 
                                    background-color:lch(from var(--estado-color) l c h / 5%);
                                    color:lch(from var(--estado-color) l calc(c + 10) h);
                                `'>
                                {{estado.descripcion}}
                            </span>
                            <span v-else class='tw-py-1 mt-1 tw-block'></span>
                        </div>
                        <div class=''>
                            <span style='color:var(--primary-color)'><strong>{{fecha}}</strong></span>
                        </div>
                        <div>
                            <span style='text-transform:capitalize;'><strong>Dr. {{medico.toLowerCase()}}</strong></span>
                        </div>
                        <div>
                            <span style='text-transform:capitalize;'>{{especialidad.toLowerCase()}}</span>
                        </div>
                    </div>
                    <div class='md:tw-ml-auto tw-mt-4 md:tw-mt-0 md:tw-space-y-1'>
                        <div v-if='isDescargable' class='tw-flex tw-flex-col tw-items-center tw-justify-center'>
                            <div :class='{"btn--loading":isLoading, "tw-bg-transparent tw-text-primary":!isLoading}' @click='!isLoading && $emit("descargar",auditoria)' class='tw-select-none tw-cursor-pointer tw-border tw-p-1 tw-rounded tw-flex tw-items-center tw-justify-between'>
                                <span style=''><strong>Descargar</strong></span>
                                <i style='font-size:1.8rem;' class='pi pi-file-pdf'></i>
                            </div>
                            <div>
                                <span style=''><strong>Venc.: {{fechaVencimiento}}</strong></span>
                            </div>
                        </div>
                        <div v-else-if='isVencida' class='tw-select-none tw-border tw-border-red-500  tw-p-1 tw-rounded tw-flex tw-items-center tw-justify-between tw-px-2'>
                            <span style='color:rgb(239, 68, 68)'><strong>Vencida</strong></span>
                        </div>
                    </div>

                </div>
            
            </template>

            

        </Card>
    </div>

</template>

<script lang='ts'>
import { defineComponent, ref,computed, onMounted, toRefs } from "@vue/runtime-core";
import axios from '@/plugins/axios';
import moment from 'moment';
export default defineComponent({
    props:['dato', 'isLoading', 'showEstado'],
    setup(props){

        const auditoria = ref<any>(null)
        const es = moment(props.dato.datos.fecha).locale('es')
        const medico = ref(props.dato.medico?.nombre || '');
        const especialidad = ref(props.dato.especialidad?.nombre || '');
        const fecha = ref(es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY'))
        const diasVenc = ref(30);

        const isVencida = computed(()=>{
            return moment().diff(es,'days') > diasVenc.value
        })

        const isDescargable = computed(()=>{
            const isOrdenImprimible = (props.dato.tipo === 'OR' && props.dato.datos?.tipoSolicitud !== 'medicamento') ? auditoria.value?.prestEstado?.imprime : true
            return isOrdenImprimible && !isVencida.value
        })
        
        const fechaVencimiento = computed(()=>{
            return es.add(diasVenc.value,'days').format('DD/MM/YYYY')
        })

        const estado = computed(()=>{
            return auditoria.value?.prestEstado
        })


        const obtenerEstadoAuditoria = async ()=>{
            try {
                const estadoEnTramite = {
                    descripcion:'En Trámite',
                    color:'#414141',
                    id:0,
                    imprime:false,
                }
                const estadosPosibles = ['Autorizado','Rechazado']
                const idauditoria = props.dato?.datos?.idauditoria

                if(!idauditoria) return

                const {data:[prestAuditoria]} = await axios.get(`/auditoria-prestacional/get-all`, {
                    params:{
                        filters:{
                            id:idauditoria
                        },
                        attributes:JSON.stringify(['id','uuid']),
                        includes:JSON.stringify([{
                            model:'prestEstados',
                        }])
                    }
                })

                if(prestAuditoria.prestEstado.descripcion === 'Autorizado Automatico'){
                    prestAuditoria.prestEstado.descripcion = 'Autorizado'
                }

                if(!estadosPosibles.includes(prestAuditoria.prestEstado.descripcion)){
                    prestAuditoria.prestEstado = estadoEnTramite
                }
                auditoria.value = prestAuditoria
            } catch (error) {
                console.log(error)
            } 
        }


        onMounted(()=>{
            if(props.dato.tipo === 'OR' && props.showEstado){
                obtenerEstadoAuditoria()
            }
            if(props.dato.tipo === 'OR' || props.dato?.datos?.items?.[0]?.practica){
                diasVenc.value = 60;
            }
        })


        return {
            estado,
            auditoria,
            medico,
            especialidad,
            fecha,
            fechaVencimiento,
            isDescargable,
            isVencida,
        }
    }

})
</script>

<style lang='postcss' scoped>
    .btn--loading {
        @apply tw-relative tw-isolate tw-text-primary tw-cursor-not-allowed;
    }
    .btn--loading::after {
        content: "";
        z-index: -1;
        animation: load 4s infinite cubic-bezier(
            0.65, 0.05, 0.36, 1
        ) forwards; 
        background-color: lch(from var(--primary-color) l c h / 20%);
        @apply tw-absolute tw-inset-0 tw-w-0 tw-rounded;
        
    }

    @keyframes load {
        to{
            width: 100%;
        }
    }

</style>