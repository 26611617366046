<template>
  <div class='tw-w-full tw-bg-gray-50'>
      <Card class='p-shadow-5 tw-h-full'>
          <template #content>
                <div class='tw-flex tw-flex-col md:tw-flex-row'>
                    <div class='tw-w-full md:tw-text-left! tw-flex tw-flex-col tw-items-center md:tw-items-start tw-justify-center'>
                        <div  class='tw-flex tw-flex-col tw-space-y-2'>
                            <div>
                                <span style='color:var(--primary-color);'><strong>{{fecha}}</strong></span>
                            </div>
                            <div>
                                <span style='text-transform:capitalize;'><strong>{{datos.datos.nombre.toLowerCase()}} </strong></span>
                            </div>
                        </div>
                    </div>
                    <div class='tw-mx-auto md:tw-ml-auto tw-flex md:tw-flex-col tw-flex-wrap tw-gap-2 tw-text-sm'>
                        
                        <div @click='$emit("ver")' class='tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-select-none tw-w-full tw-border tw-p-1 tw-rounded'>
                            <span style='color:var(--primary-color)'><strong>{{ isPac ? 'Descargar Informe' : 'Descargar PDF' }}</strong></span>
                            <i style='font-size:2em; color:var(--primary-color);' class='pi pi-file-pdf'></i>
                        </div>

                        <div v-if='isPac && verImagenesEstudios' @click='$emit("visualizar")' class='tw-flex tw-items-center tw-justify-center tw-gap-2 tw-cursor-pointer tw-select-none tw-w-full tw-border tw-p-1 tw-rounded'>
                            <span style='color:var(--primary-color)'><strong>Ver Estudio</strong></span>
                            <i style='font-size:2em; color:var(--primary-color);' class='pi pi-image'></i>
                        </div>

                    </div>

                </div>
          </template>
      </Card>
  </div>

</template>

<script lang='ts'>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex';

import moment from 'moment';

export default defineComponent({
    props:['datos'],
    setup(props){
        const store = useStore()
        const es = moment(props.datos.datos.fecha).locale('es')
        const fecha = ref(es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY'))
        

        const isPac = computed(() => {
            return props.datos.tipo === 'PAC'
        })


        const verImagenesEstudios = computed(() => store?.state?.configuracionPortal?.ver_imagenes_estudios)
        
        return {
            fecha,
            isPac,
            verImagenesEstudios,
        }
    }
})
</script>

<style>

</style>