import { useStore } from 'vuex';



const useLogo = () =>{

    const store = useStore();

    return store.state.configuracionPortal.cliente.logo ? 
        store.state.configuracionPortal.cliente.logo :
        ''


}



export default useLogo